var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.currentRole === 4 || _vm.currentRole === 5)?_c('div',{staticClass:"mt-5 mb-5 mr-8 ml-8"},[_c('h2',[_vm._v("Side Activity Note")]),_c('v-row',{class:'absent-header justify-space-between align-center'},[_c('v-col',[_c('div',{class:'d-flex justify-start'},[_c('v-autocomplete',{class:'select-input',attrs:{"item-text":"location","append-icon":"keyboard_arrow_down","item-value":"locationID","items":_vm.listLocation,"label":"Center","multiple":"","outlined":"","dense":""},on:{"input":_vm.changeLocation},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-list-item',{attrs:{"ripple":""},on:{"mousedown":function($event){$event.preventDefault();},"click":function($event){return _vm.toggle()}}},[_c('v-list-item-action',[(_vm.listLocation.length === _vm.locationID.length)?_c('v-icon',[_vm._v(" mdi-close-box ")]):_c('v-icon',[_vm._v(" mdi-checkbox-blank-outline ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Select All ")])],1)],1)]},proxy:true},{key:"selection",fn:function(ref){
var index = ref.index;
return [(index == 0)?_c('span',[_vm._v(" "+_vm._s(_vm.locationID.length)+" selected ")]):_vm._e()]}}],null,false,2945667788),model:{value:(_vm.locationID),callback:function ($$v) {_vm.locationID=$$v},expression:"locationID"}}),_c('v-select',{class:'select-input',attrs:{"item-text":"text","append-icon":"keyboard_arrow_down","item-value":"id","items":_vm.activityTypeList.map(function (res) {
              return Object.assign({}, res, {text: res.name + ' - ' + res.code})
            }),"label":"Activity type","outlined":"","dense":""},model:{value:(_vm.activityTypeID),callback:function ($$v) {_vm.activityTypeID=$$v},expression:"activityTypeID"}})],1)])],1),_c('div',{staticClass:"d-flex justify-space-between align-center mb-2"},[_c('h4',[_vm._v("Total : "+_vm._s(_vm.listActivitiesRigister.length)+" records")])]),(_vm.loading)?[_c('div',{staticClass:"pa-6"},[_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}})],1)]:_vm._e(),[(!_vm.loading)?_c('v-data-table',{class:'elevation-0 activity-regis-table',attrs:{"headers":_vm.headers,"items":_vm.filtered,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"hide-default-footer":"","fixed-header":"","id":"sideActivityNote","height":"69vh"},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
            var props = ref.props;
return [_c('tr',{staticClass:"fixed-row-filter header-activity-note"},_vm._l((props.headers),function(header){return _c('th',{key:header.text,style:({ minWidth: '80px' })},[(_vm.filters.hasOwnProperty(header.value))?_c('div',[(_vm.filters.hasOwnProperty(header.value))?_c('Autocomplete',{attrs:{"styleBorder":'border-bottom',"keyFilter":header.value,"selectedInit":_vm.filters[header.value],"listItem":header.value === 'remark'
                    ? ['Remark', 'No Remark']
                    : _vm.groupColumnValueList(header.value)},on:{"setFilter":_vm.setFilter}}):_vm._e()],1):_vm._e()])}),0)]}},{key:"item.no",fn:function(ref){
                    var index = ref.index;
return [_c('p',{on:{"click":function($event){return _vm.view(_vm.item.userID)}}},[_vm._v(_vm._s(index + 1))])]}},{key:"item.studentCode",fn:function(ref){
                    var item = ref.item;
return [_c('p',{staticClass:"link w-50",on:{"click":function($event){return _vm.viewAccount(item.studentID)}}},[_vm._v(_vm._s(item.studentCode))])]}},{key:"item.studentName",fn:function(ref){
                    var item = ref.item;
return [_c('p',{staticClass:"link w-150",on:{"click":function($event){return _vm.viewAccount(item.studentID)}}},[_vm._v(_vm._s(item.studentName))])]}},{key:"item.classCode",fn:function(ref){
                    var item = ref.item;
return [_c('p',{staticClass:"link w-80",on:{"click":function($event){return _vm.viewClassDetail(item.classID)}}},[_vm._v(_vm._s(item.classCode))])]}},{key:"item.qc",fn:function(ref){
                    var item = ref.item;
return [_c('p',{staticClass:"link w-150",on:{"click":function($event){return _vm.viewAccount(item.qcID)}}},[_vm._v(_vm._s(item.qc))])]}},{key:"item.ec",fn:function(ref){
                    var item = ref.item;
return [_c('p',{staticClass:"link w-150",on:{"click":function($event){return _vm.viewAccount(item.ecID)}}},[_vm._v(_vm._s(item.ec))])]}},{key:"item.counselorPayment",fn:function(ref){
                    var item = ref.item;
return [_c('p',{staticClass:"link w-150",on:{"click":function($event){return _vm.viewAccount(item.counselorPaymentID)}}},[_vm._v(" "+_vm._s(item.counselorPayment)+" ")])]}},{key:"item.paymentStatus",fn:function(ref){
                    var item = ref.item;
return [_c('p',{staticClass:"statusRe",class:item.registrationStatus == 'Full paid'
              ? 'Full'
              : item.registrationStatus == 'Advance paid'
              ? 'Advance'
              : item.paymentStatus},[_vm._v(" "+_vm._s(item.paymentStatus)+" ")])]}},{key:"item.activityClassCode",fn:function(ref){
              var item = ref.item;
return [_c('p',{staticClass:"link w-80",on:{"click":function($event){return _vm.viewClassDetail(item.activityClassID)}}},[_vm._v(" "+_vm._s(item.activityClassCode)+" ")])]}},{key:"item.startDate",fn:function(ref){
              var item = ref.item;
return [_c('p',{staticClass:"w-80"},[_vm._v(" "+_vm._s(item.startDate)+" ")])]}},{key:"item.registrationDate",fn:function(ref){
              var item = ref.item;
return [_c('p',{staticClass:"w-80"},[_vm._v(" "+_vm._s(item.registrationDate)+" ")])]}},{key:"item.endDate",fn:function(ref){
              var item = ref.item;
return [_c('p',{staticClass:"w-80"},[_vm._v(" "+_vm._s(item.endDate)+" ")])]}},{key:"item.registrationStatus",fn:function(ref){
              var item = ref.item;
return [_c('p',{staticClass:"w-80 statusRe",class:item.registrationStatus == 'No response' ? 'No' : item.registrationStatus},[_vm._v(" "+_vm._s(item.registrationStatus)+" ")])]}},{key:"item.PaidDate",fn:function(ref){
              var item = ref.item;
return [_c('p',{staticClass:"w-80"},[_vm._v(" "+_vm._s(item.PaidDate)+" ")])]}},{key:"item.remark",fn:function(ref){
              var item = ref.item;
return [_c('p',{staticClass:"short-text"},[_vm._v(" "+_vm._s(item.remark)+" ")])]}},{key:"item.action",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"size":"18","color":"primary"},on:{"click":function($event){return _vm.openEditNote(item)}}},'v-icon',attrs,false),on),[_vm._v("visibility ")])]}}],null,true)},[_c('span',[_vm._v("View note")])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.overlay),expression:"overlay"}],class:'h'},[_c('div',{staticClass:"card-i"},[_c('v-card',{style:({ boxShadow: 'none' }),attrs:{"light":"","min-width":_vm.screen < 700 ? '500' : '600'}},[_c('v-card-title',{staticClass:"text-h5",style:({ fontWeight: 700 })},[_vm._v(" Side Activity note ")]),_c('v-divider'),_c('v-textarea',{key:_vm.index,staticClass:"abc mt-3 ml-5 mr-5",attrs:{"v-model":_vm.sideRemark,"value":_vm.sideRemark,"name":"input-7-1","disabled":true,"outlined":"","dense":"","height":"30vh","id":"abcd"}}),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('div',{staticClass:"btnNote",class:'mb-4'},[_c('v-btn',{attrs:{"elevation":"2","large":""},on:{"click":function($event){_vm.overlay = false}}},[_vm._v("Cancel")])],1)],1)],1)],1)]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('img',_vm._g(_vm._b({attrs:{"src":require("../../assets/images/icon-edit-pen.svg"),"alt":"","srcset":""},on:{"click":function($event){return _vm.setLog({ openDialog: true, register: item })}}},'img',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v("Edit")])])],1)]}}],null,true)}):_vm._e(),(!_vm.isLoading)?_c('div',{staticClass:"pl-10 mt-3 pr-10",attrs:{"color":"#fff"}},[_c('v-row',[_c('v-col',{attrs:{"md":"2"}},[_c('v-select',{staticStyle:{"height":"32px"},attrs:{"outlined":"","hide-details":"","dense":"","value":_vm.itemsPerPage,"items":[20, 50, 100, 200]},on:{"change":function($event){_vm.itemsPerPage = parseInt($event, 10)}}})],1),_c('v-col',{staticClass:"d-flex align-center justify-end",attrs:{"md":"4"}},[_c('h3')]),_c('v-col',{staticClass:"d-flex justify-end",attrs:{"md":"6"}},[_c('v-pagination',{attrs:{"length":_vm.pageCount,"elevation":"0","total-visible":7},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1):_vm._e()],(_vm.dialogForm)?_c('FormRegister',{attrs:{"regisInfor":_vm.regisSelect,"dialog":_vm.dialogForm},on:{"setLog":_vm.setLog,"callData":_vm.callDataWithNoLoading}}):_vm._e()],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }